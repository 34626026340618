<template>
<div>
    <app-header></app-header>

    <div class="login">
        <div class="login-logo text-center">
            <img src="../../assets/images/logo.png" alt="" class="" />
            <p class="text-center">{{ $t("Log in to Here Home") }}</p>
        </div>
        <div class="text-center">
            <form method="post" @submit.prevent="save()">
                <div class="page">
                    <div class="field">
                        <label for="name">{{ $t("email") }}</label>
                        <InputText id="name" type="text" v-model="body.email" :class="{ 'is-invalid': isInvalid && !body.email }" :placeholder="$t('email')" />
                    </div>
                    <div class="field">
                        <label for="password">{{ $t("password") }}</label>
                        <Password :feedback="false" toggleMask id="password" v-model="body.password" :placeholder="$t('password')" :class="{ 'is-invalid': isInvalid && !body.password }" />
                    </div>

                    <router-link to="/forgetPassword" class="frogetPassword"> {{ $t('forget your password?') }}</router-link>
                    <button class="btn btn-primary btn-login" type="submit" :disabled="disabledAdd">
                        {{ $t("sign in") }}
                    </button>
                </div>
            </form>
            <div class="page">
                <p class="loginby">
                    <span> {{$t('Log in with')}}</span>
                </p>
            </div>

            <div class="loginbyschciol">
                <!-- <a :href="$baseURL + '/api/auth/apple'" style="display: inline-block">
                    <img src="../../assets/images/apple.png" alt="apple" />
                </a> -->
                <a :href="$baseURL + '/api/auth/instagram'" style="display: inline-block">
                    <img src="../../assets/images/instagram.png" alt="instagram" />
                </a>
                <a :href="$baseURL + '/api/auth/twitter'" style="display: inline-block">
                    <img src="../../assets/images/twitter.png" alt="twitter" />
                </a>
                <a :href="$baseURL + '/api/auth/snapchat'" style="display: inline-block">
                    <img src="../../assets/images/snapchat.png" alt="snapchat" />
                </a>
                <a :href="$baseURL + '/api/auth/tiktok'" style="display: inline-block">
                    <img src="../../assets/images/tiktok.png" alt="tiktok" />
                </a>
                <a :href="$baseURL + '/api/auth/google'" style="display: inline-block">
                    <img src="../../assets/images/google.png" alt="" />
                </a>
                <!-- <a
            :href="$baseURL + '/api/auth/facebook'"
            style="display: inline-block"
          >
            <img src="../../assets/images/facebook.svg" alt="" />
          </a> -->
            </div>

            <div class="linked">
                {{ $t("Do not have an account?") }}
                <router-link to="/regstar">{{ $t("new registration") }}</router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
    components: {
        AppHeader,
    },
    data() {
        return {
            body: {
                email: null,
                password: null,
            },
            isInvalid: false,
            disabledAdd: false,
        };
    },

    methods: {
        save() {
            if (
                this.body.email &&
                this.body.password &&
                this.disabledAdd == false
            ) {
                this.disabledAdd = true;

                this.$http.post("auth/login", this.body).then(
                    (res) => {
                        this.disabledAdd = false;
                        localStorage.homeHere = JSON.stringify(res.data.data);

                        location.reload();
                        this.$toast.add({
                            severity: "success",
                            summary: this.$t("Done Successfully"),
                            detail: this.$t("Login Successfully"),
                            life: 3000,
                        });
                    },
                    (err) => {
                        this.disabledAdd = false;

                        this.$toast.add({
                            severity: "error",
                            summary: this.$t("Error"),
                            detail: err.response.data.message,
                            life: 3000,
                        });
                    }
                );
            } else {
                this.isInvalid = true;
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: this.$t("Please add the required fields"),
                    life: 3000,
                });
            }
        },
    },
    async created() {},
};
</script>

<style>
</style>
